import { useState, useEffect, useRef, useContext, cloneElement } from "react";
import { Modal } from "antd";
import { BuyItem, UserContext } from "./App";

const basePath = "/res/apps/SteffanoBrueder/";

const priceWorld = 2.99;
const priceTotal = 24.99;

const worlds = [
  {
    ground: "UPElement 162288-3.png",
    background: "Welt 1Test.png",
    obstacles: ["rock-g054c292d2_640.png", "green-gbf0bbbe77_640.png"],
    image: "UI Weltenauswahl 9.1/UPElement 170288.png",
  },
  {
    ground: "UPElement 161288-3.png",
    background: "Welt 2.png",
    obstacles: ["cacti-g1354d4488_640.png", "aloe-vera-g531e4416c_640.png"],
    image: "UI Weltenauswahl 9.1/UPElement 171288-2.png",
  },
  {
    ground: "UPElement 160288-3.png",
    background: "B5.png",
    obstacles: ["wolf-gd28cc52eb_640.png", "animal-g212513b66_640.png"],
    image: "UI Weltenauswahl 9.1/UPElement 172288-2.png",
  },
  {
    ground: "UPElement 159288-3.png",
    background: "B3.png",
    obstacles: ["ice-cream-g1c5f93f72_640.png", "crystals-g89282436c_640.png"],
    image: "UI Weltenauswahl 9.1/UPElement 181288-2.png",
  },
  {
    ground: "UPElement 158288-3.png",
    background: "B2.png",
    obstacles: ["shark-g37e0df23b_640.png", "hippopotamus-gf5199d6e5_640.png"],
    image: "UI Weltenauswahl 9.1/UPElement 180288-2.png",
  },
  {
    ground: "UPElement 157288-3.png",
    background: "B11.png",
    obstacles: ["calabash-g0b7b7e2ce_640.png", "bat-gce29531c2_640.png"],
    image: "UI Weltenauswahl 9.1/UPElement 179288-2.png",
  },
  {
    ground: "UPElement 156288-3.png",
    background: "B9.png",
    obstacles: ["spider-web-g88b68ded6_640.png", "cartoon-gfd9e49879_640.png"],
    image: "UI Weltenauswahl 9.1/UPElement 178288-2.png",
  },
  {
    ground: "UPElement 155288-3.png",
    background: "B6.png",
    obstacles: ["spider-g0d2d23962_640.png", "orange-g1a726e063_640.png"],
    image: "UI Weltenauswahl 9.1/UPElement 177288-2.png",
  },
  {
    ground: "UPElement 154288-3.png",
    background: "B12.png",
    obstacles: ["rocket-g32d3bf0d5_640.png", "meteorite-g9efbeaf69_640.png"],
    image: "UI Weltenauswahl 9.1/UPElement 176288-2.png",
  },
  {
    ground: "UPElement 153288-3.png",
    background: "B10.png",
    obstacles: ["atmosphere-gcfa43d66b_640.png", "aliens-gde7ddfb24_640.png"],
    image: "UI Weltenauswahl 9.1/UPElement 175288-2.png",
  },
  {
    ground: "UPElement 152288-3.png",
    background: "B13.png",
    obstacles: ["planet-g5a3a28fb8_640.png", "fire-ge5cb3fed7_640.png"],
    image: "UI Weltenauswahl 9.1/UPElement 174288-2.png",
  },
  {
    ground: "UPElement 151288-3.png",
    background: "B4.png",
    obstacles: ["cake-gfe1613c92_640.png", "balloons-g47a5e4235_640.png"],
    image: "UI Weltenauswahl 9.1/UPElement 173288-2.png",
  },
];

const loadImage = (src, imageLoader) => {
  const img = new Image();
  img.src = basePath + src;
  if (!imageLoader.count) imageLoader.count = 0;
  else imageLoader.count++;
  img.onload = () => {
    imageLoader.count--;
    if (imageLoader.count <= 0) imageLoader.onFinish();
  };
  return img;
};

export const SpielSteffanoBrueder = () => {
  const { apps, updateApp } = useContext(UserContext);
  const app = apps.find((app) => app.name === "STEFFANO BRÜDER 2D DELUXE");

  const [playing, setPlaying] = useState();

  const [worldConfig, setWorldConfig] = useState([
    ...worlds.map((world, key) => ({
      highscore: 0,
      unlocked: key === 0 ? true : false,
    })),
  ]);

  const changeWorldConfig = (callback) => {
    const data = callback();
    app.data = data;
    updateApp(app);
    setWorldConfig(data);
  };

  useEffect(() => {
    if (app.data) {
      setWorldConfig(app.data);
    }
  }, []);

  if (playing !== undefined)
    return (
      <Play
        world={playing}
        worldData={worlds[playing]}
        worldConfig={worldConfig}
        changeWorldConfig={changeWorldConfig}
        onClose={() => setPlaying(undefined)}
      />
    );

  return (
    <div
      style={{
        background:
          "linear-gradient(180deg, rgba(157,209,235,1) 0%, rgba(188,224,91,1) 32%, rgba(47,152,0,1) 81%)",
        height: "100%",
      }}
    >
      <img
        alt=""
        style={{ width: "100%" }}
        src={basePath + "UI Weltenauswahl 9.1/UPElement 191288-3.png"}
      />
      <div
        style={{
          display: "flex",
          flexWrap: "wrap",
          justifyContent: "space-around",
          paddingTop: "8%",
        }}
      >
        {worlds.map((world, key) => (
          <UnlockWorld
            key={key}
            world={key}
            worldConfig={worldConfig}
            changeWorldConfig={changeWorldConfig}
            setPlaying={setPlaying}
          >
            <div
              className="hoverScale"
              style={{ width: "20%", margin: "5%", position: "relative" }}
            >
              <img
                alt=""
                style={{ width: "100%" }}
                src={basePath + world.image}
              />
              {!worldConfig[key].unlocked && (
                <img
                  alt=""
                  style={{
                    width: "20px",
                    position: "absolute",
                    bottom: "20%",
                    right: "0px",
                  }}
                  src={
                    basePath + "UI Weltenauswahl 9.1/padlock-g11deefa45_640.png"
                  }
                />
              )}
            </div>
          </UnlockWorld>
        ))}
      </div>
    </div>
  );
};

function UnlockWorld({
  world,
  children,
  worldConfig,
  changeWorldConfig,
  setPlaying,
}) {
  const [visible, setVisible] = useState(false);

  const handleClick = () => {
    if (!worldConfig[world].unlocked) return setVisible(true);
    setPlaying(world);
  };

  return (
    <>
      {cloneElement(children, { onClick: handleClick })}
      <Modal
        transitionName=""
        onCancel={() => setVisible(false)}
        closable={false}
        bodyStyle={{
          background:
            "url('" +
            basePath +
            "UI Welt-Gesperrt-Pop-Up 9.1.1/UPElement 189288-3.png')",
          textAlign: "center",
          backgroundSize: "100% 100%",
          height: "700px",
          fontSize: "1.2em",
          position: "relative",
        }}
        open={visible}
        width={460}
        footer={false}
      >
        <div
          style={{
            position: "absolute",
            left: "60px",
            right: "60px",
            top: "250px",
          }}
        >
          <b>
            Du kannst diese Welt für nur {priceWorld.toFixed(2)}€ freischalten.
          </b>
          <BuyItem
            preis={priceWorld}
            onSuccess={() => {
              changeWorldConfig(() => {
                worldConfig[world].unlocked = true;
                return [...worldConfig];
              });
              setVisible(false);
            }}
          >
            <div
              className="hoverScale"
              style={{ position: "relative", marginTop: "10px" }}
            >
              <img
                alt=""
                style={{ width: "100%" }}
                src={
                  basePath +
                  "UI Welt-Gesperrt-Pop-Up 9.1.1/UPElement 186288-3.png"
                }
              />
              <div
                style={{
                  position: "absolute",
                  top: "20%",
                  left: "0",
                  right: "0",
                  fontWeight: "bold",
                  color: "#e48971",
                }}
              >
                Welt {world + 1} freischalten
                <br />
                {priceWorld.toFixed(2)}€
              </div>
            </div>
          </BuyItem>
        </div>
        <div
          style={{
            position: "absolute",
            left: "60px",
            right: "60px",
            top: "480px",
          }}
        >
          <b>Alle Welten für nur {priceTotal.toFixed(2)}€ freischalten.</b>
          <BuyItem
            preis={priceTotal}
            onSuccess={() => {
              changeWorldConfig(() =>
                worldConfig.map((world) => ({
                  ...world,
                  unlocked: true,
                }))
              );
              setVisible(false);
            }}
          >
            <div
              className="hoverScale"
              style={{ position: "relative", marginTop: "10px" }}
            >
              <img
                alt=""
                style={{ width: "100%" }}
                src={
                  basePath +
                  "UI Welt-Gesperrt-Pop-Up 9.1.1/UPElement 186288-3.png"
                }
              />
              <div
                style={{
                  position: "absolute",
                  top: "20%",
                  left: "0",
                  right: "0",
                  fontWeight: "bold",
                  color: "#e48971",
                }}
              >
                Alle freischalten
                <br />
                {priceTotal.toFixed(2)}€
              </div>
            </div>
          </BuyItem>
        </div>
        <img
          alt=""
          onClick={() => setVisible(false)}
          style={{
            position: "absolute",
            left: "20px",
            top: "20px",
            width: "40px",
          }}
          src={
            basePath + "UI Welt-Gesperrt-Pop-Up 9.1.1/UPElement 187288-3.png"
          }
        />
      </Modal>
    </>
  );
}

function Play({ world, worldData, worldConfig, changeWorldConfig, onClose }) {
  const canvasRef = useRef();

  const pointsRef = useRef();
  const jumpRef = useRef();

  const canvasSize = 430;

  const worldDir = `Asset Bibliothek 9.3/Welt ${world + 1}/`;

  const init = () => {
    const imageLoader = {
      onFinish: () => {
        restart();
      },
    };
    const playerImage = loadImage("Spielfigur.png", imageLoader);
    const groundImage = loadImage(worldDir + worldData.ground, imageLoader);
    const backgroundImage = loadImage(
      worldDir + worldData.background,
      imageLoader
    );
    const obstaclesImages = worldData.obstacles.map((obstacle) =>
      loadImage(worldDir + obstacle, imageLoader)
    );

    const groundSize = 60;
    const playerHeight = 40;
    const canvas = canvasRef.current;
    const g = canvas.getContext("2d");
    const obstacleHeight = 50;
    const minSpeed = 4;
    const maxSpeed = 12;
    const backgroundSpeed = 2;

    let gameRunning = false;
    let requestAnimation;
    let obstacles, player;
    let counter;
    let backgroundPosition = 0;

    const addObstacle = () => {
      const img =
        obstaclesImages[Math.floor(Math.random() * obstaclesImages.length)];
      obstacles.push({
        img,
        x: canvasSize + Math.random() * 20,
        y: canvasSize - groundSize - obstacleHeight / 2,
        h: obstacleHeight,
        w: (obstacleHeight / img.height) * img.width,
        speed: minSpeed + Math.random() * (maxSpeed - minSpeed),
      });
    };

    const restart = (first = true) => {
      if (!first) {
        const points = player.points;
        changeWorldConfig(() => {
          if (points > worldConfig[world].highscore)
            worldConfig[world].highscore = points;
          return [...worldConfig];
        });
      }

      const playerGround = canvasSize - groundSize - playerHeight;
      player = {
        x: 20,
        y: playerGround,
        w: playerHeight * 0.8,
        h: playerHeight,
        jump: false,
        ground: playerGround,
        points: 0,
      };

      pointsRef.current.innerHTML = String(player.points).padStart(12, "0");

      obstacles = [];

      gameRunning = true;
      counter = 0;
    };

    const update = () => {
      if (!gameRunning) return;

      if (player.jump) {
        player.y -= 12;
        if (player.y < player.ground - player.h * 3) player.jump = false;
      } else if (player.y < player.ground) {
        player.y += 6;
      }

      for (let n = obstacles.length - 1; n >= 0; n--) {
        const obstacle = obstacles[n];

        if (
          obstacle.x < player.x + player.w &&
          obstacle.x + obstacle.w > player.x &&
          obstacle.y < player.y + player.h &&
          obstacle.h + obstacle.y > player.y
        )
          return restart(false);

        obstacle.x -= obstacle.speed;
        if (obstacle.x + obstacle.w <= 0) {
          player.points += 1;
          pointsRef.current.innerHTML = String(player.points).padStart(12, "0");
          obstacles.splice(n, 1);
        }
      }

      if (counter > 80 && obstacles.length === 0) {
        addObstacle();
      }

      backgroundPosition -= backgroundSpeed;

      counter++;
    };

    const draw = () => {
      requestAnimation = window.requestAnimationFrame(draw);

      if (!gameRunning) return;

      const backgroundWidthScale = 1324 / 324;

      let backgroundX =
        backgroundPosition % (canvasSize * backgroundWidthScale);

      do {
        g.drawImage(
          backgroundImage,
          backgroundX,
          0,
          canvasSize * backgroundWidthScale,
          canvasSize
        );
        backgroundX += canvasSize * backgroundWidthScale;
      } while (backgroundX < canvasSize);

      g.drawImage(
        groundImage,
        -10,
        canvasSize - groundSize,
        canvasSize + 20,
        groundSize + 10
      );

      g.drawImage(playerImage, player.x, player.y, player.w, player.h);

      obstacles.forEach((obstacle) => {
        g.drawImage(
          obstacle.img,
          obstacle.x,
          obstacle.y,
          obstacle.w,
          obstacle.h
        );
      });

      const radiusSize = counter * 20;

      if (radiusSize < canvasSize * 3) {
        g.beginPath();
        g.save();

        g.fillStyle = "black";
        g.arc(player.x, player.y, counter * 10, 0, Math.PI * 2);
        g.rect(0, 0, canvasSize, canvasSize);
        g.fill("evenodd");

        g.restore();
        g.closePath();
      }
    };

    const eventKeyDown = (e) => {
      if (e.code !== "Space") return;

      changeJump(true);
    };

    const eventKeyUp = (e) => {
      if (e.code !== "Space") return;

      changeJump(false);
    };

    const changeJump = (change) => {
      if (
        !gameRunning ||
        player.jump === change ||
        (change && player.y < player.ground)
      )
        return;

      player.jump = change;
    };

    const eventJumpDown = () => changeJump(true);
    const eventJumpUp = () => changeJump(false);

    draw();
    const updateInterval = setInterval(update, 30);
    jumpRef.current.addEventListener("mousedown", eventJumpDown);
    jumpRef.current.addEventListener("mouseup", eventJumpUp);
    window.addEventListener("keydown", eventKeyDown);
    window.addEventListener("keyup", eventKeyUp);

    return () => {
      window.removeEventListener("keydown", eventKeyDown);
      window.removeEventListener("keyup", eventKeyUp);
      jumpRef.current?.removeEventListener("mousedown", eventJumpDown);
      jumpRef.current?.removeEventListener("mouseup", eventJumpUp);
      window.cancelAnimationFrame(requestAnimation);
      clearInterval(updateInterval);
    };
  };

  useEffect(init, []);

  return (
    <div
      style={{
        backgroundSize: "100% 100%",
        padding: "8px",
        display: "flex",
        flexDirection: "column",
        gap: "20px",
        height: "100%",
        backgroundImage: "url('" + basePath + "background.png')",
        paddingTop: "20px",
      }}
    >
      <div style={{ position: "relative" }}>
        <img
          alt=""
          style={{ width: "100%" }}
          src={basePath + "UI Spiel 9.2/UPElement 167288-3.png"}
        />
        <div
          style={{
            position: "absolute",
            right: "20px",
            top: "4px",
            color: "#e48971",
            fontWeight: "bold",
            fontSize: "32px",
          }}
        >
          {String(worldConfig[world].highscore).padStart(12, "0")}
        </div>
      </div>
      <div style={{ position: "relative", background: "white" }}>
        <img
          alt=""
          style={{ width: "100%" }}
          src={basePath + "UI Spiel 9.2/UPElement 169288-3.png"}
        />
        <canvas
          ref={canvasRef}
          width={canvasSize}
          height={canvasSize}
          style={{
            position: "absolute",
            left: "8px",
            top: "8px",
          }}
        ></canvas>
      </div>
      <div style={{ position: "relative" }}>
        <img
          alt=""
          style={{ width: "100%" }}
          src={basePath + "UI Spiel 9.2/UPElement 168288-3.png"}
        />
        <div
          style={{
            position: "absolute",
            right: "20px",
            top: "4px",
            color: "#e48971",
            fontWeight: "bold",
            fontSize: "32px",
          }}
          ref={pointsRef}
        ></div>
      </div>
      <img
        alt=""
        ref={jumpRef}
        style={{ width: "100%" }}
        src={basePath + "UI Spiel 9.2/UPElement 164288-3.png"}
      />
      <img
        alt=""
        onClick={() => onClose()}
        style={{ width: "100%" }}
        src={basePath + "UI Spiel 9.2/UPElement 166288-3.png"}
      />
    </div>
  );
}
