import "./App.css";
import {
  Space,
  Form,
  Input,
  Typography,
  Divider,
  Modal,
  List,
  notification,
  message,
  Card,
  Tag,
} from "antd";
import {
  createContext,
  useContext,
  cloneElement,
  useState,
  useEffect,
  useLayoutEffect,
} from "react";
import Button from "antd-button-color";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  useNavigate,
  useParams,
  Link,
} from "react-router-dom";
import { SpielDreiNimmt } from "./SpielDreiNimmt";
import { SpielSuperEpicAutorennen } from "./SpielSuperEpicAutorennen";
import { SpielSteffanoBrueder } from "./SpielSteffanoBrueder";
import { SpielKekseEssen } from "./SpielKekseEssen";
import { Apps } from "./config/Apps";
import { User } from "./config/User";
const { Title, Text } = Typography;

notification.config({
  top: 50,
  duration: 4,
});

message.config({
  top: 50,
  duration: 2,
});

export const UserContext = createContext();

const App = () => {
  const [apps, setApps] = useState(Apps);
  const [costs, setCosts] = useState(0);

  const updateApp = (app) => {
    setApps((currentApps) => {
      currentApps[currentApps.findIndex((row) => row.name === app.name)] = app;
      return [...currentApps];
    });
  };

  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <UserContext.Provider
        value={{ costs, setCosts, apps, setApps, updateApp }}
      >
        <Button
          style={{
            margin: "8px",
          }}
          onClick={() =>
            Modal.info({
              title: "Deine Ausgaben",
              content: (
                <>
                  Du hast bisher <b>{costs.toFixed(2)}€</b> ausgegeben
                </>
              ),
            })
          }
        >
          Deine Ausgaben
        </Button>
        <div className="phone-container">
          <div className="phone-top"></div>
          <div className="phone-screen">
            <div className="phone-display">
              <div className="phone-bar">
                <div className="phone-time">10:30</div>
                <div className="phone-infos">
                  <img
                    alt=""
                    height="24"
                    src="/res/phone/wifi-connection.png"
                  />
                  <img alt="" height="24" src="/res/phone/wifi.png" />
                  <img alt="" height="24" src="/res/phone/battery.png" />
                </div>
              </div>
              <div className="phone-content">
                <Router>
                  <Routes>
                    <Route path="/" element={<Start />} />
                    <Route path="/welcome" element={<Welcome />} />
                    <Route path="/apps" element={<AppsOverview />} />
                    <Route path="/app/:appName" element={<AppDetail />} />
                    <Route
                      path="/spielDreiNimmt"
                      element={<SpielDreiNimmt />}
                    />
                    <Route
                      path="/spielSuperEpicAutorennen"
                      element={<SpielSuperEpicAutorennen />}
                    />
                    <Route
                      path="/spielSteffanoBrueder"
                      element={<SpielSteffanoBrueder />}
                    />
                    <Route
                      path="/spielKekseEssen"
                      element={<SpielKekseEssen />}
                    />
                  </Routes>
                </Router>
              </div>
            </div>
          </div>
        </div>
      </UserContext.Provider>
    </>
  );
};

const Start = () => {
  const navigate = useNavigate();

  const handleSubmit = (values) => {
    if (values.username === User.name && values.password === User.password)
      navigate("/welcome");
    else message.error("Benutzername oder Passwort Falsch!");
  };

  return (
    <>
      <div className="center">
        <img alt="" height="90" src="/res/logo.png" />
      </div>
      <div style={{ margin: "20%", textAlign: "center" }}>
        <p>
          <Text strong>
            Sie müssen sich anmelden um Apps runterladen zu können!
          </Text>
        </p>
        <Form onFinish={handleSubmit}>
          <Form.Item
            name="username"
            rules={[{ required: true, message: "Bitte Benutzername eingeben" }]}
          >
            <Input placeholder="Benutzername" />
          </Form.Item>
          <Form.Item
            name="password"
            rules={[{ required: true, message: "Bitte Passwort eingeben" }]}
          >
            <Input.Password placeholder="Passwort" />
          </Form.Item>
          <Form.Item>
            <Button type="info" htmlType="submit">
              Anmelden
            </Button>
          </Form.Item>
        </Form>
      </div>
    </>
  );
};

const Welcome = () => {
  return (
    <>
      <div className="center">
        <img alt="" height="90" src="/res/logo.png" />
      </div>
      <Card>
        <Space direction="vertical" align="center">
          <Text strong>Willkommen zurück</Text>
          <Title>{User.name}</Title>

          <img alt="" height="60" src="/res/avatar.png" />
          <Link to="/apps">
            <Button type="info">Zu den Apps</Button>
          </Link>
          <Text italic>
            Folgende Ihrer bereits gespeicherten Daten wurden auf diesem Gerät
            für Sie verfügbar gemacht: Speicherstände, heruntergeladene Apps,
            gekaufte Apps, Bewertungen, Favoriten, Adresse, Profilangaben,
            Zahlungsoptionen, Bank-Verbindung, Sonstiges
          </Text>
        </Space>
      </Card>
    </>
  );
};

const AppsOverview = () => {
  const navigate = useNavigate();

  const { apps, updateApp } = useContext(UserContext);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <div
        style={{
          backgroundColor: "white",
          borderTop: "2px solid black",
          borderBottom: "2px solid black",
        }}
      >
        <Space size={200}>
          <img alt="" height="50" src="/res/icon.png" />
          <Space>
            <Text strong>Angemeldet als: {User.name}</Text>
            <img alt="" height="60" src="/res/avatar.png" />
          </Space>
        </Space>
      </div>
      <Divider />

      <List
        dataSource={apps}
        renderItem={(app, key) => (
          <Card>
            <Space align="start">
              <Link to={"/app/" + app.name}>
                <img alt="" height="120" src={"/res/apps/" + app.icon} />
              </Link>
              <Space direction="vertical" size={0} className="smaller">
                <Link to={"/app/" + app.name}>
                  <Title className="noMargin" level={3}>
                    {app.name}
                  </Title>
                </Link>

                <Text>
                  {app.sterne}★ | Mehr als {app.downloads} Downloads | USK ab{" "}
                  {app.usk}
                </Text>
                <Text italic>Version: {app.version}</Text>
                <Text italic>Anbieter: {app.anbieter}</Text>
                <Text italic>
                  <ul className="noMargin">
                    {app.infos.map((info, key) => (
                      <li key={key}>{info}</li>
                    ))}
                  </ul>
                </Text>
                <Text italic>für schnelles und einfaches Zahlen</Text>
              </Space>
              <Space direction="vertical" align="end">
                <img
                  alt=""
                  height="40"
                  src={"/res/usk/icons8-usk-" + app.usk + "-100.png"}
                />

                {!app.gekauft && (
                  <BuyApp app={app} changer={(changed) => updateApp(changed)} />
                )}
                {app.gekauft && (
                  <>
                    {!app.installiert && (
                      <InstallApp
                        app={app}
                        changer={(changed) => updateApp(changed)}
                      />
                    )}
                    {app.installiert && (
                      <Button
                        type="info"
                        onClick={() => {
                          if (app.link) navigate(app.link);
                          else message.error("App kann nicht geöffnet werden!");
                        }}
                      >
                        Öffnen
                      </Button>
                    )}
                  </>
                )}
              </Space>
            </Space>
          </Card>
        )}
      ></List>
    </>
  );
};

export const BuyItem = ({ children, preis, onSuccess }) => {
  const user = useContext(UserContext);

  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isSuccessWindowModalVisible, setIsSuccessWindowModalVisible] =
    useState(false);

  const PAYMENTS = ["Bank Konto", "LoboPay"];

  const [payment, setPayment] = useState(0);

  const showModal = (e) => {
    setIsModalVisible(true);
  };

  const handleCancel = (e) => {
    setIsModalVisible(false);
  };

  const handleOk = (e) => {
    user.setCosts((costs) => costs + +preis);

    setIsModalVisible(false);
    setIsSuccessWindowModalVisible(true);
  };

  const handleCloseSuccessWindow = (e) => {
    setIsSuccessWindowModalVisible(false);
    onSuccess();
  };

  return (
    <>
      {cloneElement(children, { onClick: showModal })}
      <Modal
        transitionName=""
        width={460}
        open={isModalVisible}
        onCancel={handleCancel}
        footer={
          <Space>
            <a onClick={handleCancel}>Abbrechen</a>
            <Button onClick={handleOk} type="info">
              Jetzt kostenpflichtig kaufen
            </Button>
          </Space>
        }
      >
        <Space align="center" direction="vertical">
          <Title level={4}>In-App-Kauf bestätigen</Title>
          <Text>
            Betrag: <Text strong>{preis}€</Text> inkl. Mwst.
          </Text>
          <Text>
            Zahlungsmittel: <Text strong>{PAYMENTS[payment]}</Text>{" "}
            <a
              onClick={() => {
                setPayment((payment) =>
                  payment + 1 >= PAYMENTS.length ? 0 : payment + 1
                );
              }}
            >
              ändern
            </a>
          </Text>
          <Space direction="vertical" size={0}>
            <Text italic>Inhaber: {User.inhaber}</Text>
            <Text italic>Konto-Nr: {User.kontoNr}</Text>
            <Text italic>BLZ: {User.blz}</Text>
          </Space>
        </Space>
      </Modal>

      <Modal
        transitionName=""
        width={460}
        closable={false}
        open={isSuccessWindowModalVisible}
        footer={
          <Button type="info" onClick={handleCloseSuccessWindow}>
            Zurück zum Spiel
          </Button>
        }
      >
        <Space align="center" direction="vertical">
          <Title level={4}>Glückwunsch!</Title>
          <Text>Zahlung war efolgreich.</Text>
        </Space>
      </Modal>
    </>
  );
};

const BuyApp = ({ app, changer }) => {
  const user = useContext(UserContext);

  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isSuccessWindowModalVisible, setIsSuccessWindowModalVisible] =
    useState(false);

  const PAYMENTS = ["Bank Konto", "LoboPay"];

  const [payment, setPayment] = useState(0);

  const showModal = (e) => {
    setIsModalVisible(true);
  };

  const handleCancel = (e) => {
    setIsModalVisible(false);
  };

  const handleOk = (e) => {
    if (user.credit - app.preis < 0) return message.error("Kein Guthaben");
    user.setCosts((costs) => costs + app.preis);

    message.success("App wurde gekauft!");

    app.gekauft = true;

    setIsModalVisible(false);
    setIsSuccessWindowModalVisible(true);
  };

  const handleCloseSuccessWindow = (e) => {
    changer(app);

    setIsSuccessWindowModalVisible(false);
  };

  return (
    <>
      <Button onClick={showModal} type="info">
        {app.preis}€ Kaufen
      </Button>

      <Modal
        transitionName=""
        width={460}
        open={isModalVisible}
        onCancel={handleCancel}
        footer={
          <Space>
            <a onClick={handleCancel}>Zurück</a>
            <Button onClick={handleOk} type="info">
              Jetzt kostenpflichtig kaufen
            </Button>
          </Space>
        }
      >
        <Space align="center" direction="vertical">
          <Title level={4}>
            Möchtest du <span>{app.name}</span> kaufen?
          </Title>
          <Text>
            Betrag: <Text strong>{app.preis}€</Text> inkl. Mwst.
          </Text>
          <Text>
            Zahlungsmittel: <Text strong>{PAYMENTS[payment]}</Text>{" "}
            <a
              onClick={() => {
                setPayment((payment) =>
                  payment + 1 >= PAYMENTS.length ? 0 : payment + 1
                );
              }}
            >
              ändern
            </a>
          </Text>
          <Space direction="vertical" size={0}>
            <Text italic>Inhaber: {User.inhaber}</Text>
            <Text italic>Konto-Nr: {User.kontoNr}</Text>
            <Text italic>BLZ: {User.blz}</Text>
          </Space>
        </Space>
      </Modal>

      <Modal
        transitionName=""
        width={460}
        open={isSuccessWindowModalVisible}
        footer={
          <Space>
            <a onClick={handleCloseSuccessWindow}>Später installieren</a>
            <InstallApp
              app={app}
              changer={(changed) => {
                setIsSuccessWindowModalVisible(false);
                changer(app);
              }}
            />
          </Space>
        }
      >
        <Space align="center" direction="vertical">
          <Title level={4}>Glückwunsch!</Title>
          <Text>Zahlung war efolgreich.</Text>
        </Space>
      </Modal>
    </>
  );
};

const InstallApp = ({ app, changer }) => {
  const [isModalVisible, setIsModalVisible] = useState(false);

  const showModal = (e) => {
    setIsModalVisible(true);
  };

  const handleCancel = (e) => {
    setIsModalVisible(false);
  };

  const handleOk = (e) => {
    message.success("App wurde installiert!");

    app.installiert = true;
    changer(app);

    setIsModalVisible(false);
  };

  return (
    <>
      <Button onClick={showModal} type="info">
        Installieren
      </Button>
      <Modal
        transitionName=""
        width={460}
        open={isModalVisible}
        onCancel={handleCancel}
        footer={
          <Space>
            <a onClick={handleCancel}>Zurück</a>
            <Button onClick={handleOk} type="info">
              Jetzt installieren
            </Button>
          </Space>
        }
      >
        <Space align="center" direction="vertical">
          <Title level={4}>
            Soll <span>{app.name}</span> installiert werden?
          </Title>
          <Text>Die App benötigt folgende Berechtigungen:</Text>
          <ul>
            {app.berechtigungen.map((berechtigung, key) => (
              <li key={key}>{berechtigung}</li>
            ))}
          </ul>
        </Space>
      </Modal>
    </>
  );
};

const AppDetail = () => {
  const navigate = useNavigate();

  const { apps, updateApp } = useContext(UserContext);

  const { appName } = useParams();
  const app = apps.find((app) => app.name === appName);

  return (
    <>
      <Link
        to="/apps"
        style={{ position: "absolute", left: 0, top: 40, zIndex: 2 }}
      >
        <Button type="info" size="small">
          Zurück
        </Button>
      </Link>

      <Card>
        <div>
          <Space direction="vertical">
            <Space align="start">
              <img alt="" height="120" src={"/res/apps/" + app.icon} />
              <Space direction="vertical" size={0} className="smaller">
                <Title className="noMargin" level={4}>
                  {app.name}
                </Title>

                <Space wrap size={5}>
                  {app.kategorien.map((kategorie, key) => (
                    <Tag color="geekblue" key={key}>
                      {kategorie}
                    </Tag>
                  ))}
                </Space>
                <Text>{app.sterne}★</Text>
                <Text>Mehr als {app.downloads} Downloads</Text>
                <Text>USK ab {app.usk}</Text>
              </Space>
              <Space direction="vertical" align="end">
                {!app.gekauft && (
                  <BuyApp app={app} changer={(changed) => updateApp(changed)} />
                )}
                {app.gekauft && (
                  <>
                    {!app.installiert && (
                      <InstallApp
                        app={app}
                        changer={(changed) => updateApp(changed)}
                      />
                    )}
                    {app.installiert && (
                      <Button
                        type="info"
                        onClick={() => {
                          if (app.link) navigate(app.link);
                          else message.error("App kann nicht geöffnet werden!");
                        }}
                      >
                        Öffnen
                      </Button>
                    )}
                  </>
                )}

                <img
                  alt=""
                  height="40"
                  src={"/res/usk/icons8-usk-" + app.usk + "-100.png"}
                />
              </Space>
            </Space>

            <img alt="" width="100%" src={"/res/img/" + app.bild} />

            <Text strong>Beschreibung</Text>
            <Text>{app.description}</Text>

            <Space className="smaller" align="start" size={100}>
              <Space direction="vertical" size={0}>
                <Text>Berechtigungen:</Text>
                <ul>
                  {app.berechtigungen.map((berechtigung, key) => (
                    <li key={key}>{berechtigung}</li>
                  ))}
                </ul>
              </Space>
              <Space direction="vertical" size={0}>
                <Text italic>Version: {app.version}</Text>
                <Text italic>Anbieter: {app.anbieter}</Text>
                <Text italic>
                  <ul className="noMargin">
                    {app.infos.map((info, key) => (
                      <li key={key}>{info}</li>
                    ))}
                  </ul>
                </Text>
                <Text italic>für schnelles und einfaches Zahlen</Text>
              </Space>
            </Space>
          </Space>
        </div>
      </Card>
    </>
  );
};

export default App;
