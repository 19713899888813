import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import "antd/dist/antd.css";
import "antd-button-color/dist/css/style.css";
import { ConfigProvider } from "antd";
import deDE from "antd/es/locale/de_DE";
import moment from "moment";
import "moment/locale/de";

Array.prototype.sample = function (max = this.length) {
  return this[Math.floor(Math.random() * max)];
};

Array.prototype.shuffle = function () {
  for (let i = this.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [this[i], this[j]] = [this[j], this[i]];
  }
  return this;
};

moment.locale("de");

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <ConfigProvider locale={deDE}>
      <App />
    </ConfigProvider>
  </React.StrictMode>
);
