import { useState, useEffect, useRef, useContext } from "react";
import { Space, Modal, message } from "antd";
import { useNavigate } from "react-router-dom";
import { BuyItem } from "./App";
import { UserContext } from "./App";

const loadImage = (src, basePath = "res/apps/SuperEpicAutorennen/") => {
  const img = new Image();
  img.src = basePath + src;
  return img;
};

export const SpielSuperEpicAutorennen = () => {
  const { apps, updateApp } = useContext(UserContext);
  const app = apps.find((app) => app.name === "SUPER EPIC AUTORENNEN");

  const [infoWindowVisible, setInfoWindowVisible] = useState(true);
  const [crashWindowVisible, setCrashWindowVisible] = useState(false);
  const [toolWindowVisible, setToolWindowVisible] = useState(false);
  const [carWindowVisible, setCarWindowVisible] = useState(false);

  const canvasRef = useRef();
  const pointsRef = useRef();
  const buttonRestartRef = useRef();

  const [highscore, setHighscore] = useState(0);

  const gameRef = useRef();

  const saveHighscore = (newHighscore) => {
    setHighscore(newHighscore);
    app.data.highscore = newHighscore;
    updateApp(app);
  };

  const saveCarShop = (newCarShop) => {
    setCarShop(newCarShop);
    app.data.carShop = newCarShop;
    updateApp(app);
  };

  const saveTools = (newTools) => {
    setTools(newTools);
    app.data.tools = newTools;
    updateApp(app);
  };

  const loadData = () => {
    if (!app?.data) {
      if (app) app.data = { highscore, carShop, tools };
      return;
    }

    setHighscore(app.data.highscore);
    setCarShop(app.data.carShop);
    setTools(app.data.tools);
  };

  useEffect(loadData, []);

  const [tools, setTools] = useState(3);
  const [lifes, setLifes] = useState(3);
  const [carShop, setCarShop] = useState([
    {
      price: 24.99,
      object: [
        loadImage("Plane1.png"),
        loadImage("Plane2.png"),
        loadImage("Plane3.png"),
      ],
      banner: "/res/apps/SuperEpicAutorennen/UPElement 1513.3.2.png",
      buyButton: "/res/apps/SuperEpicAutorennen/3.2.1.png",
      buyed: false,
      useButton: "/res/apps/SuperEpicAutorennen/1.2.png",
      airplane: true,
      objectWidth: 120,
      collision: false,
    },
    {
      price: 0.05,
      object: loadImage("Dunkler Sportwagen - Senkrecht.png"),
      banner: "/res/apps/SuperEpicAutorennen/2.1.3.png",
      buyButton: "/res/apps/SuperEpicAutorennen/2.1.0.png",
      buyed: false,
      useButton: "/res/apps/SuperEpicAutorennen/1.2.png",
      objectWidth: 60,
      collision: true,
    },
    {
      price: 0.99,
      object: loadImage("DX1000K Senkrecht.png"),
      banner: "/res/apps/SuperEpicAutorennen/2.2.3.png",
      buyButton: "/res/apps/SuperEpicAutorennen/2.2.0.png",
      buyed: false,
      useButton: "/res/apps/SuperEpicAutorennen/1.2.png",
      objectWidth: 60,
      collision: true,
    },
    {
      price: 1.99,
      object: loadImage("Retro Racer - Senkrecht.png"),
      banner: "/res/apps/SuperEpicAutorennen/2.3.3.png",
      buyButton: "/res/apps/SuperEpicAutorennen/2.3.0.png",
      buyed: false,
      useButton: "/res/apps/SuperEpicAutorennen/1.2.png",
      objectWidth: 60,
      collision: true,
    },
    {
      price: 5.99,
      object: loadImage("/F1 Rennwagen - Senkrecht.png"),
      banner: "/res/apps/SuperEpicAutorennen/2.4.3.png",
      buyButton: "/res/apps/SuperEpicAutorennen/2.4.0.png",
      buyed: false,
      useButton: "/res/apps/SuperEpicAutorennen/1.2.png",
      objectWidth: 60,
      collision: true,
    },
    {
      price: 9.99,
      object: loadImage("Monster LKW - Senkrecht.png"),
      banner: "/res/apps/SuperEpicAutorennen/2.5.3.png",
      buyButton: "/res/apps/SuperEpicAutorennen/2.5.0.png",
      buyed: false,
      useButton: "/res/apps/SuperEpicAutorennen/1.2.png",
      objectWidth: 80,
      collision: false,
    },
  ]);

  const navigate = useNavigate();

  const handleClose = () => {
    navigate("/apps");
  };

  const init = () => {
    const randomCarPosition = () =>
      0 - (Math.random() * canvas.height * 2 + canvas.height / 4);

    const canvas = canvasRef.current;
    const g = canvas.getContext("2d");

    const streetImage = loadImage("UPElement 149288.png");
    const crashImage = loadImage("Unfall 3.png");

    const carImages = [
      loadImage("Nicht Spieler Auto 1.png"),
      loadImage("Nicht Spieler Auto 2.png"),
      loadImage("Nicht Spieler Auto 3.png"),
    ];

    let requestAnimation;

    let cars,
      player,
      points = 0,
      counter = 0;

    const partSize = canvas.width / 5;

    gameRef.doubleCount = false;
    gameRef.lifes = 3;
    gameRef.car = loadImage("car-g456bb01d9_640-2.png");
    gameRef.carWidth = 60;
    gameRef.carCollision = true;
    gameRef.running = false;
    gameRef.continue = false;

    player = {
      left: 200,
      top: 350,
      width: gameRef.carWidth,
      height: gameRef.carWidth * 2,
      dir: undefined,
      moveSpeed: 4,
      crashed: false,
      image: gameRef.car,
      collision: gameRef.carCollision,
      acceleration: undefined,
      accelerationSpeed: 1,
    };

    cars = [];

    for (let n = 0; n < 6; n++) {
      cars.push({
        left: partSize * n + 50 / 2,
        top: randomCarPosition(),
        speed: 2,
        width: 50,
        height: 100,
        image: carImages.sample(),
        obsolete: false,
      });
    }

    const restart = (reset = false) => {
      if (reset) {
        setLifes(3);

        if (points > highscore) {
          saveHighscore(points);
        }

        points = 0;

        pointsRef.current.innerHTML = "";
      }

      gameRef.running = true;

      cars.forEach((car) => {
        car.top = randomCarPosition();
        car.obsolete = false;
      });

      player.image = gameRef.car;
      player.width = gameRef.carWidth;
      player.height = player.width * 2;
      player.collision = gameRef.carCollision;
      player.left = canvas.width / 2 - player.width / 2;
      player.top = canvas.height - player.height;

      player.crashed = false;

      gameRef.continue = false;
    };

    const loop = () => {
      requestAnimation = window.requestAnimationFrame(loop);

      if (gameRef.continue) restart();

      if (player.crashed || !gameRef.running) return draw();

      if (player.dir === "left") {
        if (player.left > 0) player.left -= player.moveSpeed;
      } else if (player.dir === "right") {
        if (player.left + player.width < canvas.width)
          player.left += player.moveSpeed;
      }

      if (player.acceleration === "top") {
        if (player.top > canvas.height / 2)
          player.top -= player.accelerationSpeed;
      } else if (player.acceleration === "bottom") {
        if (player.top < canvas.height - player.height)
          player.top += player.accelerationSpeed;
      }

      for (let car of cars) {
        if (car.crash) continue;

        car.top += car.speed;

        if (car.top >= canvas.height) {
          car.top = randomCarPosition();
          car.speed = Math.random() + 1;
          car.image = carImages.sample();
          car.obsolete = false;
        }

        if (car.top > player.top && !car.obsolete) {
          car.obsolete = true;
          points++;
          if (gameRef.doubleCount) points++;

          pointsRef.current.innerHTML = points;
        }

        const hitPlayer = !(
          player.left > car.left + car.width ||
          player.left + player.width < car.left ||
          player.top > car.top + car.height ||
          player.top + player.height < car.top
        );

        if (player.collision && hitPlayer) {
          player.crashed = true;

          setLifes((lifes) => {
            if (lifes > 0) {
              setTimeout(restart, 2000);
              return lifes - 1;
            }

            setCrashWindowVisible(true);
            return lifes;
          });

          break;
        }
      }

      counter++;

      draw();
    };

    const draw = () => {
      g.save();

      const streetOverflow = 85;
      g.drawImage(
        streetImage,
        0,
        (counter % streetOverflow) - streetOverflow,
        canvas.width,
        canvas.height + streetOverflow
      );
      g.fillStyle = "black";

      cars.forEach((car) => {
        g.drawImage(car.image, car.left, car.top, car.width, car.height);
      });

      g.drawImage(
        player.image,
        player.left,
        player.top,
        player.width,
        player.height
      );

      if (player.crashed) {
        g.drawImage(crashImage, player.left - 25, player.top - 50, 100, 100);
      }

      g.restore();
    };

    const eventKeyUp = (e) => {
      player.acceleration = undefined;
      player.dir = undefined;
    };

    const eventKeyDown = (e) => {
      if (e.key === "ArrowUp") {
        player.acceleration = "top";
      } else if (e.key === "ArrowDown") {
        player.acceleration = "bottom";
      } else if (e.key === "ArrowRight") {
        player.dir = "right";
      } else if (e.key === "ArrowLeft") {
        player.dir = "left";
      }
    };

    window.addEventListener("keyup", eventKeyUp);
    window.addEventListener("keydown", eventKeyDown);

    buttonRestartRef.current.addEventListener("click", (e) => {
      restart(true);
    });

    loop();

    return () => {
      window.removeEventListener("keydown", eventKeyDown);
      window.removeEventListener("keyup", eventKeyUp);
      window.cancelAnimationFrame(requestAnimation);
    };
  };

  useEffect(init, []);

  const buyTools = (toolsCount, price, doubleCount = false) => {
    saveTools(tools + toolsCount);

    message.success(`Du hast ${toolsCount} Werkzeuge für ${price}€ gekauft!`);

    if (doubleCount) {
      gameRef.doubleCount = true;
      message.success("Deine Punkte werden ab jetzt verdoppelt!");
    }
  };

  const buyShopItem = (item) => {
    item.buyed = true;
    saveCarShop([...carShop]);

    message.success(`Du hast ${item.price}€ ausgegeben!`);
  };

  const shopUseItem = (item, object) => {
    gameRef.car = object;
    gameRef.carWidth = item.objectWidth;
    gameRef.carCollision = item.collision;

    message.success(`Du hast dein Fahrzeug gewechselt!`);
  };

  return (
    <>
      <Modal
        transitionName=""
        onCancel={() => {
          setCarWindowVisible(false);
        }}
        bodyStyle={styles.carModal}
        open={carWindowVisible}
        width={460}
        footer={false}
        className="modalTransparent"
      >
        <div style={{ ...styles.backgroundModal, padding: "30px" }}>
          <Space direction="vertical">
            {carShop.map((item, key) => (
              <div key={key} className="relative">
                <img alt="" width="100%" src={item.banner} />
                {!item.buyed && (
                  <BuyItem
                    preis={item.price}
                    onSuccess={() => buyShopItem(item)}
                  >
                    <img
                      alt=""
                      className="hoverScale"
                      style={styles.buyButtonList}
                      height={item.airplane ? 75 : 50}
                      src={item.buyButton}
                    />
                  </BuyItem>
                )}
                {item.buyed && (
                  <>
                    {!item.airplane && (
                      <img
                        alt=""
                        onClick={() => shopUseItem(item, item.object)}
                        className="hoverScale"
                        style={styles.buyButtonList}
                        height={50}
                        src={item.useButton}
                      />
                    )}
                    {item.airplane && (
                      <>
                        {item.object.map((obj, key) => (
                          <img
                            alt=""
                            key={key}
                            onClick={() => shopUseItem(item, obj)}
                            className="hoverScale"
                            style={{
                              ...styles.buyButtonList,
                              left: 10 + key * 130 + "px",
                              bottom: "30px",
                            }}
                            height={50}
                            src={item.useButton}
                          />
                        ))}
                      </>
                    )}
                  </>
                )}
              </div>
            ))}
          </Space>
        </div>
      </Modal>

      <Modal
        transitionName=""
        onCancel={() => {
          setToolWindowVisible(false);
        }}
        bodyStyle={styles.backgroundModal}
        open={toolWindowVisible}
        width={460}
        footer={false}
        className="modalTransparent"
      >
        <Space direction="vertical">
          <div className="relative">
            <img
              alt=""
              width="100%"
              src="/res/apps/SuperEpicAutorennen/UPElement 127288.png"
            />
            <BuyItem preis="69.99" onSuccess={() => buyTools(500, 69.99, true)}>
              <img
                alt=""
                className="hoverScale"
                style={styles.buyButtonList}
                height="50"
                src="/res/apps/SuperEpicAutorennen/UPElement 118288.png"
              />
            </BuyItem>
          </div>
          <div className="relative">
            <img
              alt=""
              width="100%"
              src="/res/apps/SuperEpicAutorennen/UPElement 131288.png"
            />
            <BuyItem preis="0.99" onSuccess={() => buyTools(2, 0.99)}>
              <img
                alt=""
                className="hoverScale"
                style={styles.buyButtonList}
                height="50"
                src="/res/apps/SuperEpicAutorennen/UPElement 120288.png"
              />
            </BuyItem>
          </div>
          <div className="relative">
            <img
              alt=""
              width="100%"
              src="/res/apps/SuperEpicAutorennen/UPElement 132288.png"
            />
            <BuyItem preis="3.99" onSuccess={() => buyTools(5, 3.99)}>
              <img
                alt=""
                className="hoverScale"
                style={styles.buyButtonList}
                height="50"
                src="/res/apps/SuperEpicAutorennen/UPElement 121288.png"
              />
            </BuyItem>
          </div>
          <div className="relative">
            <img
              alt=""
              width="100%"
              src="/res/apps/SuperEpicAutorennen/UPElement 133288.png"
            />
            <BuyItem preis="8.99" onSuccess={() => buyTools(10, 8.99)}>
              <img
                alt=""
                className="hoverScale"
                style={styles.buyButtonList}
                height="50"
                src="/res/apps/SuperEpicAutorennen/UPElement 122288.png"
              />
            </BuyItem>
          </div>
          <div className="relative">
            <img
              alt=""
              width="100%"
              src="/res/apps/SuperEpicAutorennen/UPElement 134288.png"
            />
            <BuyItem preis="19.99" onSuccess={() => buyTools(50, 19.99)}>
              <img
                alt=""
                className="hoverScale"
                style={styles.buyButtonList}
                height="50"
                src="/res/apps/SuperEpicAutorennen/UPElement 142288.png"
              />
            </BuyItem>
          </div>
        </Space>
      </Modal>

      <Modal
        transitionName=""
        bodyStyle={styles.backgroundModal}
        open={crashWindowVisible}
        width={460}
        footer={false}
        className="modalTransparent"
      >
        <Space>
          <img
            alt=""
            width="100%"
            src="/res/apps/SuperEpicAutorennen/Unfall 1.png"
          />
          <img
            alt=""
            width="100%"
            src="/res/apps/SuperEpicAutorennen/Unfall 2.png"
          />
        </Space>
        <h1 style={{ color: "white" }}>Du hast keine Leben mehr!</h1>
        <Space>
          {tools > 0 && (
            <img
              alt=""
              onClick={() => {
                if (tools > 0) {
                  setLifes((lifes) => (lifes += 1));
                  saveTools(tools - 1);
                }

                gameRef.continue = true;
                setCrashWindowVisible(false);
              }}
              className="hoverScale"
              width="100%"
              src="/res/apps/SuperEpicAutorennen/UPElement 147288.png"
            />
          )}
          <img
            alt=""
            onClick={() => {
              buttonRestartRef.current.click();
              setCrashWindowVisible(false);
            }}
            className="hoverScale"
            width="100%"
            src="/res/apps/SuperEpicAutorennen/UPElement 146288.png"
          />
        </Space>
      </Modal>

      <Modal
        transitionName=""
        bodyStyle={styles.manual}
        open={infoWindowVisible}
        width={460}
        footer={false}
        className="modalTransparent"
      >
        <Space direction="vertical">
          <img
            alt=""
            width="100%"
            src="/res/apps/SuperEpicAutorennen/UPElement 45288.png"
          />
          <div>Steuere deinen Rennwagen!</div>
          <div>Überhole dabei deine Gegner!</div>
          <div>Pass aber auf, dass du keinen Unfall baust!</div>
          <div>So steuerst du:</div>
          <Space>
            <Space direction="vertical">
              <div>Spur nach links!</div>
            </Space>
            <Space direction="vertical">
              <div>Nach Vorne!</div>
            </Space>
            <Space direction="vertical">
              <div>Nach Hinten!</div>
            </Space>
            <Space direction="vertical">
              <div>Spur nach rechts!</div>
            </Space>
          </Space>
          <img
            alt=""
            width="100%"
            src="/res/apps/SuperEpicAutorennen/UPElement 53288.png"
          />

          <div>Wenn du einen Unfall baust verlierst du ein Leben.</div>
          <div>
            Du kannst ein Werkzeug einsetzen um dir ein Leben aufzufüllen.
          </div>

          <Space align="flex-end">
            <img
              alt=""
              width="100%"
              src="/res/apps/SuperEpicAutorennen/UPElement 48288.png"
            />
            <img
              alt=""
              width="100%"
              src="/res/apps/SuperEpicAutorennen/UPElement 47288.png"
            />
          </Space>
          <div>
            Wenn du keine Leben mehr hast kannst du entweder neustarten oder
            Werkzeug benutzen!
          </div>
          <img
            alt=""
            className="hoverScale"
            onClick={() => {
              buttonRestartRef.current.click();
              setInfoWindowVisible(false);
            }}
            width="100%"
            src="/res/apps/SuperEpicAutorennen/UPElement 46288.png"
          />
        </Space>
      </Modal>

      <div style={styles.background}>
        <Space direction="vertical" size={10}>
          <div className="relative">
            <div style={styles.lifes}>
              Leben: <span>{lifes}</span>
            </div>
            <div style={styles.points}>
              Platz: <span ref={pointsRef}></span>
            </div>
            <div style={styles.highscore}>
              Rekord: <span>{String(highscore).padStart(5, "0")}</span>
            </div>
            <Space>
              <img
                alt=""
                width="100%"
                src="/res/apps/SuperEpicAutorennen/UPElement 77288.png"
              />
              <img
                alt=""
                width="100%"
                src="/res/apps/SuperEpicAutorennen/UPElement 78288.png"
              />
            </Space>
          </div>

          <canvas ref={canvasRef} width="440" height="520"></canvas>

          <Space>
            <img
              alt=""
              width="100%"
              src="/res/apps/SuperEpicAutorennen/UPElement 54288.png"
            />
            <Space direction="vertical">
              <div className="relative">
                <img
                  alt=""
                  onClick={() => {
                    if (tools > 0) {
                      setLifes((lifes) => (lifes += 1));
                      saveTools(tools - 1);
                    }
                  }}
                  className="hoverScale"
                  width="100%"
                  src="/res/apps/SuperEpicAutorennen/UPElement 1523.3.2.png"
                />
                <div style={styles.tools}>
                  Werkzeuge einsetzen (Noch <span>{tools}</span> Mal)
                </div>
              </div>
              <Space>
                <img
                  alt=""
                  width="100%"
                  src="/res/apps/SuperEpicAutorennen/UPElement 55288.png"
                />
                <img
                  alt=""
                  width="100%"
                  src="/res/apps/SuperEpicAutorennen/UPElement 56288.png"
                />
              </Space>
            </Space>
            <img
              alt=""
              width="100%"
              src="/res/apps/SuperEpicAutorennen/UPElement 57288.png"
            />
          </Space>

          <Space>
            <img
              alt=""
              onClick={() => {
                setCarWindowVisible(true);
              }}
              className="hoverScale"
              width="100%"
              src="/res/apps/SuperEpicAutorennen/UPElement 59288.png"
            />
            <img
              alt=""
              onClick={() => {
                setToolWindowVisible(true);
              }}
              className="hoverScale"
              width="100%"
              src="/res/apps/SuperEpicAutorennen/UPElement 62288.png"
            />
          </Space>

          <Space>
            <img
              alt=""
              onClick={handleClose}
              className="hoverScale"
              width="100%"
              src="/res/apps/SuperEpicAutorennen/UPElement 60288.png"
            />
            <img
              alt=""
              ref={buttonRestartRef}
              className="hoverScale"
              width="100%"
              src="/res/apps/SuperEpicAutorennen/UPElement 61288.png"
            />
          </Space>
        </Space>
      </div>
    </>
  );
};

const styles = {
  tools: {
    fontSize: ".8em",
    fontWeight: "bold",
    position: "absolute",
    top: "5px",
    right: "5px",
    color: "black",
    pointerEvents: "none",
  },
  lifes: {
    fontSize: "1.2em",
    fontWeight: "bold",
    position: "absolute",
    top: "5px",
    left: "20px",
    color: "red",
  },
  points: {
    fontSize: "1.2em",
    fontWeight: "bold",
    position: "absolute",
    top: "0px",
    left: "280px",
    color: "red",
  },
  highscore: {
    fontSize: "0.8em",
    fontWeight: "bold",
    position: "absolute",
    top: "20px",
    left: "280px",
    color: "red",
  },
  manual: {
    background: "url('/res/apps/SuperEpicAutorennen/Back.png')",
    backgroundSize: "100% 100%",
    color: "white",
    textAlign: "center",
    fontSize: "1.1em",
  },
  backgroundModal: {
    background: "url('/res/apps/SuperEpicAutorennen/Back.png')",
    backgroundSize: "100% 100%",
    color: "white",
    textAlign: "center",
    fontSize: "1.1em",
  },
  carModal: {
    maxHeight: "700px",
    overflowY: "auto",
    padding: "0px",
  },
  buyButtonList: {
    position: "absolute",
    right: "20px",
    bottom: "15px",
  },
  background: {
    background: "url('/res/apps/SuperEpicAutorennen/UPElement 1533.3.2.png')",
    textAlign: "center",
    backgroundSize: "100% 100%",
    fontSize: "1.2em",
    padding: "0px",
    paddingTop: "10px",
    paddingBottom: "10px",
    overflow: "hidden",
  },
};
