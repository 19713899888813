import { useState, useEffect, useContext, cloneElement } from "react";
import { Modal } from "antd";
import { UserContext } from "./App";
import { useNavigate } from "react-router-dom";
import RubberBand from "react-reveal/RubberBand";
import Fade from "react-reveal/Fade";

const basePath = "/res/apps/KekseEssen/";

const shopItemPath = basePath + "UI Shop 10.1.1 - 10.1.3/Upgrades/";

const ShopItems = [
  {
    src: "1.png",
    price: 20,
    value: 1,
  },
  {
    src: "2.png",
    price: 200,
    value: 2,
  },
  {
    src: "3.png",
    price: 600,
    value: 3,
  },
  {
    src: "4.png",
    price: 1200,
    value: 5,
  },
  {
    src: "5.png",
    price: 2500,
    value: 10,
  },
  {
    src: "6.png",
    price: 4500,
    value: 15,
  },
  {
    src: "7.png",
    price: 7500,
    value: 20,
  },
  {
    src: "8.png",
    price: 12000,
    value: 25,
  },
  {
    src: "9.png",
    price: 17000,
    value: 50,
  },
  {
    src: "10.png",
    price: 27000,
    value: 100,
  },
  {
    src: "11.png",
    price: 47000,
    value: 120,
  },
  {
    src: "12.png",
    price: 71000,
    value: 140,
  },
  {
    src: "13.png",
    price: 100000,
    value: 160,
  },
  {
    src: "14.png",
    price: 135000,
    value: 200,
  },
  {
    src: "15.png",
    price: 175000,
    value: 300,
  },
  {
    src: "16.png",
    price: 235000,
    value: 500,
  },
  {
    src: "17.png",
    price: 335000,
    value: 750,
  },
  {
    src: "18.png",
    price: 485000,
    value: 800,
  },
  {
    src: "19.png",
    price: 645000,
    value: 1000,
  },
  {
    src: "20.png",
    price: 1700000,
    value: 2500,
  },
  {
    src: "21.png",
    price: 2800000,
    value: 5000,
  },
  {
    src: "22.png",
    price: 4800000,
    value: 10000,
  },
  {
    src: "23.png",
    price: 14000000,
    value: 12000,
  },
  {
    src: "24.png",
    price: 25000000,
    value: 15000,
  },
  {
    src: "25.png",
    price: 50000000,
    value: 100000,
  },
  {
    src: "26.png",
    price: 400000000,
    value: 1000000,
  },
  {
    src: "27.png",
    price: 2000000000,
    value: false,
  },
  {
    src: "28.png",
    price: 50000000000,
    value: 100000000,
  },
];

const loadImage = (src, imageLoader) => {
  const img = new Image();
  img.src = basePath + src;
  if (!imageLoader.count) imageLoader.count = 0;
  else imageLoader.count++;
  img.onload = () => {
    imageLoader.count--;
    if (imageLoader.count <= 0) imageLoader.onFinish();
  };
  return img;
};

export const SpielKekseEssen = () => {
  const { apps, updateApp } = useContext(UserContext);
  const app = apps.find((app) => app.name === "KEKSE ESSEN WETTKAMPF");

  const [cookies, setCookies] = useState(app?.data?.cookies || 0);
  const [buyedItems, setBuysItems] = useState(
    app?.data?.buyedItems || ShopItems.map((item) => 0)
  );
  const [additive, setAdditive] = useState(app?.data?.additive || 1);
  const [multiplicator, setMultiplicator] = useState(
    app?.data?.multiplicator || 1
  );
  const [points, setPoints] = useState([]);

  const navigate = useNavigate();

  function save() {
    app.data = {
      multiplicator,
      buyedItems,
      additive,
      cookies,
    };
    updateApp(app);
  }

  function addPoint(value, x, y) {
    setPoints((points) => [...points, { id: Date.now(), x, y, value }]);
  }

  useEffect(() => {
    const interval = setInterval(() => {
      setPoints((points) =>
        points.filter((point) => Date.now() - point.id < 1000)
      );
    });

    return () => {
      clearInterval(interval);
    };
  }, []);

  return (
    <div
      style={{
        backgroundSize: "100% 100%",
        padding: "12px",
        display: "flex",
        flexDirection: "column",
        gap: "20px",
        height: "100%",
        backgroundImage:
          "url('" + basePath + "UI Spiel 10.1/UPElement 155.png')",
        paddingTop: "20px",
      }}
    >
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <Shop
          cookies={cookies}
          setCookies={setCookies}
          buyedItems={buyedItems}
          setBuysItems={setBuysItems}
          setAdditive={setAdditive}
          setMultiplicator={setMultiplicator}
        >
          <img
            alt=""
            width={200}
            src={basePath + "UI Spiel 10.1/UPElement 151.png"}
            className="hoverScale"
          />
        </Shop>

        <img
          onClick={() => {
            save();
            navigate("/apps");
          }}
          alt=""
          width={200}
          src={basePath + "UI Spiel 10.1/UPElement 152.png"}
          className="hoverScale"
        />
      </div>
      <div
        style={{
          flex: "1",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <RubberBand spy={cookies}>
          <img
            onClick={(e) => {
              const deservedCookies = additive * multiplicator;
              addPoint(deservedCookies, e.clientX, e.clientY - 60);
              setCookies((cookies) => cookies + deservedCookies);
            }}
            alt=""
            width="80%"
            style={{ WebkitUserDrag: "none" }}
            src={basePath + "UI Spiel 10.1/UPElement 153.png"}
          />
        </RubberBand>
      </div>
      <div style={{ position: "relative" }}>
        <img
          alt=""
          width="100%"
          src={basePath + "UI Spiel 10.1/UPElement 154.png"}
        />
        <div
          style={{
            fontSize: "26px",
            fontWeight: "bold",
            position: "absolute",
            left: "22px",
            top: "54px",
          }}
        >
          {String(cookies).padStart(26, "0")}
        </div>
      </div>
      {points.map((point) => (
        <Fade key={point.id} bottom>
          <div
            style={{
              fontWeight: "bold",
              position: "fixed",
              left: point.x,
              top: point.y,
              fontSize: "25px",
            }}
          >
            +{point.value}
          </div>
        </Fade>
      ))}
    </div>
  );
};

function Shop({
  children,
  setAdditive,
  cookies,
  setCookies,
  buyedItems,
  setBuysItems,
  setMultiplicator,
}) {
  const [shopVisible, setShopVisible] = useState(false);

  const handleClick = () => {
    setShopVisible(true);
  };

  return (
    <>
      {cloneElement(children, { onClick: handleClick })}
      <Modal
        transitionName=""
        onCancel={() => setShopVisible(false)}
        closable={false}
        open={shopVisible}
        width={460}
        footer={false}
        bodyStyle={{
          background: "url('" + basePath + "UI Spiel 10.1/UPElement 155.png')",
          textAlign: "center",
          backgroundSize: "100% 100%",
          fontSize: "1.2em",
          position: "relative",
        }}
      >
        <div style={{ position: "relative" }}>
          <img
            width="100%"
            alt=""
            src={
              basePath +
              "UI Shop 10.1.1 - 10.1.3/Header variants/UPElement 159.png"
            }
          />
          <img
            style={{ position: "absolute", right: 20, top: -10 }}
            alt=""
            width="40"
            src={
              basePath +
              "UI Shop 10.1.1 - 10.1.3/Header variants/UPElement 158.png"
            }
            className="hoverScale"
            onClick={() => setShopVisible(false)}
          />
        </div>
        <div
          style={{
            display: "flex",
            gap: "10px",
            marginTop: "10px",
            flexDirection: "column",
            overflow: "auto",
            height: "700px",
            padding: "20px",
          }}
        >
          {ShopItems.map((item, index) => (
            <div
              key={index}
              className="hoverScale"
              style={{ position: "relative" }}
              onClick={() => {
                if (cookies - item.price < 0)
                  return Modal.info({
                    content: "Du hast nicht genug Kekse!",
                  });
                setCookies(cookies - item.price);
                if (
                  buyedItems[index] >= 10 ||
                  (!item.value && buyedItems[index] >= 4)
                )
                  return Modal.info({
                    content: "Du hast das Maximum erreicht!",
                  });
                buyedItems[index] += 1;
                setBuysItems([...buyedItems]);
                if (item.value) {
                  setAdditive((additive) => additive + item.value);
                } else {
                  setMultiplicator((multiplicator) => multiplicator + 1);
                }
              }}
            >
              <img alt="" width="100%" src={shopItemPath + item.src} />
              <div
                style={{
                  fontWeight: "bold",
                  position: "absolute",
                  right: "95px",
                  top: "54px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  width: "40px",
                  height: "1.6em",
                }}
              >
                <div style={{ lineHeight: ".8em" }}>
                  {buyedItems[index]}
                  {(buyedItems[index] >= 10 ||
                    (!item.value && buyedItems[index] >= 4)) && <div>max</div>}
                </div>
              </div>
            </div>
          ))}
        </div>
      </Modal>
    </>
  );
}
