import {
  useState,
  useLayoutEffect,
  useEffect,
  useRef,
  useContext,
} from "react";
import { Space, Divider, Modal, message } from "antd";
import { useNavigate } from "react-router-dom";
import { BuyItem } from "./App";
import { UserContext } from "./App";

const loadImage = (src, basePath = "res/apps/DreiNimmt/") => {
  const img = new Image();
  img.src = basePath + src;
  return img;
};

export const SpielDreiNimmt = () => {
  const { apps, updateApp } = useContext(UserContext);
  const app = apps.find((app) => app.name === "DREI NIMMT");

  const [infoWindowVisible, setInfoWindowVisible] = useState(true);
  const [buyWindowVisible, setBuyWindowVisible] = useState(false);
  const [reloads, setReloads] = useState(2);
  const [highscore, setHighscore] = useState(0);

  const canvasRef = useRef();
  const pointsRef = useRef();
  const restartButtonRef = useRef();

  const gameRef = useRef();

  const navigate = useNavigate();

  const saveHighscore = (newHighscore) => {
    setHighscore(newHighscore);
    app.data.highscore = newHighscore;
    updateApp(app);
  };

  const saveReloads = (newReloads) => {
    setReloads(newReloads);
    app.data.reloads = newReloads;
    updateApp(app);
  };

  const loadData = () => {
    if (!app?.data) {
      if (app) app.data = { highscore, reloads };
      return;
    }

    setReloads(app.data?.reloads);
    setHighscore(app.data?.highscore);
  };

  useEffect(loadData, []);

  const handleClose = () => {
    navigate("/apps");
  };

  const init = () => {
    gameRef.doubleCount = false;

    const images = [
      loadImage("zebra-g8cc656fb8_640.png"),
      loadImage("sheep-geb5cc5291_640.png"),
      loadImage("hippo-ge597bdb6a_640.png"),
      loadImage("elk-g74355670d_640.png"),
      loadImage("elephant-g27528e32e_640.png"),
      loadImage("donkey-g2f4093bfb_640.png"),
      loadImage("animal-gbb40bfaf1_640.png"),
      loadImage("animal-g1d49d683c_640.png"),
    ];

    const randomType = () => Math.floor(Math.random() * 8);

    const rowCount = 8;
    const canvas = canvasRef.current;
    const g = canvas.getContext("2d");

    let requestAnimation;

    var objectMap, mapSize, objectSize;
    let selectedObjects, selectedType;
    let points = 0,
      counter = 0;

    selectedObjects = [];
    selectedType = undefined;

    mapSize = canvas.width;
    objectSize = mapSize / rowCount;

    objectMap = new Array(rowCount);
    for (let x = 0; x < rowCount; x++) {
      objectMap[x] = new Array(rowCount);
      for (let y = 0; y < rowCount; y++) {
        objectMap[x][y] = {
          type: randomType(),
          zoom: 0.9,
        };
      }
    }

    const loop = () => {
      requestAnimation = window.requestAnimationFrame(loop);

      if (gameRef.mix) mix();

      draw();
      counter++;
    };

    const draw = () => {
      g.save();

      g.clearRect(0, 0, canvas.width, canvas.height);

      g.fillStyle = "rgb(" + (points % 255) + ",0,0)";
      g.fillRect(0, 0, canvas.width, canvas.height);

      let lightValue = (counter * 0.1) % rowCount;

      for (let x = 0; x < rowCount; x++) {
        for (let y = 0; y < rowCount; y++) {
          let object = objectMap[x][y];

          if (object.zoom > 0.9) object.zoom -= 0.01;

          g.save();
          g.translate(
            x * objectSize + objectSize / 2,
            y * objectSize + objectSize / 2
          );
          g.scale(object.zoom, object.zoom);
          g.drawImage(
            images[object.type],
            -objectSize / 2,
            -objectSize / 2,
            objectSize,
            objectSize
          );

          if (Math.round(lightValue) === x || Math.round(lightValue) === y) {
            g.fillStyle = "rgba(255,255,255," + 0.1 + ")";
            g.fillRect(
              -objectSize / 2,
              -objectSize / 2,
              objectSize,
              objectSize
            );
          }

          g.restore();
        }
      }

      selectedObjects.forEach((object) => {
        g.strokeStyle = "white";
        g.lineWidth = objectSize * 0.1;
        g.strokeRect(
          object.x * objectSize,
          object.y * objectSize,
          objectSize,
          objectSize
        );
      });

      g.restore();
    };

    const restart = () => {
      if (points > highscore) {
        saveHighscore(points);
      }

      points = 0;

      pointsRef.current.innerHTML = String(points).padStart(3, "0");

      mix();
    };

    const mix = () => {
      selectedObjects = [];
      selectedType = undefined;

      for (let x = 0; x < rowCount; x++) {
        for (let y = 0; y < rowCount; y++) {
          objectMap[x][y].type = randomType();
        }
      }

      gameRef.mix = false;
    };

    const mouseevent = (e) => {
      const rect = canvas.getBoundingClientRect();
      return {
        x: Math.abs(e.clientX - rect.x),
        y: Math.abs(e.clientY - rect.y),
      };
    };

    const mousemove = (e) => {
      if (selectedType === null) return;

      const mouse = mouseevent(e);

      let mapX = Math.trunc(mouse.x / objectSize);
      let mapY = Math.trunc(mouse.y / objectSize);

      let currentObject = objectMap[mapX][mapY];

      currentObject.zoom = 1;
    };

    const mousedown = (e) => {
      if (selectedType === null) return;

      const mouse = mouseevent(e);

      let mapX = Math.trunc(mouse.x / objectSize);
      let mapY = Math.trunc(mouse.y / objectSize);

      let currentObject = objectMap[mapX][mapY];

      const isSamePosition = selectedObjects.some(
        (object) => object.x === mapX && object.y === mapY
      );

      const isNear = selectedObjects.some(
        (object) =>
          (object.x - 1 === mapX && object.y === mapY) ||
          (object.x + 1 === mapX && object.y === mapY) ||
          (object.x === mapX && object.y + 1 === mapY) ||
          (object.x === mapX && object.y - 1 === mapY)
      );

      if (isSamePosition || !isNear || currentObject.type !== selectedType) {
        selectedObjects = [];
        selectedType = currentObject.type;
      }

      selectedObjects.push({
        x: mapX,
        y: mapY,
      });

      if (selectedObjects.length >= 3) {
        points += selectedObjects.length;

        if (gameRef.doubleCount) points += selectedObjects.length;

        pointsRef.current.innerHTML = String(points).padStart(3, "0");

        selectedType = null;

        selectedObjects.forEach((object) => {
          objectMap[object.x][object.y].zoom = 1.4;
        });

        setTimeout(() => {
          selectedObjects.forEach((object) => {
            objectMap[object.x][object.y].type = randomType();
          });

          selectedObjects = [];
          selectedType = undefined;
        }, 500);
      }
    };

    canvas.addEventListener("mousedown", mousedown);
    canvas.addEventListener("mousemove", mousemove);

    restartButtonRef.current.addEventListener("click", (e) => {
      restart();
    });

    restart();

    loop();

    return () => {
      canvas.removeEventListener("mousedown", mousedown);
      canvas.removeEventListener("mousemove", mousemove);
      window.cancelAnimationFrame(requestAnimation);
    };
  };

  useEffect(init, []);

  const buyReloader = (reloadCount, price, doubleCount = false) => {
    saveReloads(reloads + reloadCount);

    message.success(
      `Du hast ${reloadCount} Mal mischen für ${price}€ gekauft!`
    );

    if (doubleCount) {
      gameRef.doubleCount = true;
      message.success("Deine Punkte werden ab jetzt verdoppelt!");
    }
  };

  return (
    <>
      <Modal
        transitionName=""
        bodyStyle={styles.manual}
        open={infoWindowVisible}
        width={460}
        footer={false}
      >
        <Space direction="vertical">
          <img
            alt=""
            href=""
            width="100%"
            src="/res/apps/DreiNimmt/UPElement 18288.png"
          />
          <div>
            Finde 3 gleiche Tiersymbole die <b>sich berühren.</b>
            Tippe alle drei <b>nacheinander</b> an damit diese verschwinden und
            du Punkte sammelst.
          </div>
          <Space>
            <img
              alt=""
              width="100"
              src="/res/apps/DreiNimmt/UPElement 21288.png"
            />
            <img
              alt=""
              width="100"
              src="/res/apps/DreiNimmt/UPElement 20288.png"
            />
          </Space>
          <div>
            Wenn du keine 3 mehr findest kannst du das Spiel <b>neustarten</b>{" "}
            oder <b>neu mischen</b>.
          </div>
          <Space>
            <img
              alt=""
              width="100"
              src="/res/apps/DreiNimmt/UPElement 42288.png"
            />
            <img
              alt=""
              width="100"
              src="/res/apps/DreiNimmt/UPElement 41288.png"
            />
          </Space>
          <div>
            Neustart setzt die Punktzahl auf 0 und du darfst von Vorne anfangen.
            Bei neu mischen behälst du deine Punkte und kannst weiter machen.
          </div>

          <img
            alt=""
            className="hoverScale"
            onClick={() => setInfoWindowVisible(false)}
            width="100%"
            src="/res/apps/DreiNimmt/UPElement 17288.png"
          />
        </Space>
      </Modal>

      <Modal
        transitionName=""
        onCancel={() => setBuyWindowVisible(false)}
        bodyStyle={styles.manual}
        open={buyWindowVisible}
        width={460}
        footer={false}
      >
        <Space direction="vertical">
          <div className="relative">
            <img alt="" width="100%" src="/res/apps/DreiNimmt/3.1.png" />
            <BuyItem
              preis="75.99"
              onSuccess={() => buyReloader(750, 75.99, true)}
            >
              <img
                alt=""
                className="hoverScale"
                style={styles.buyButtonList}
                height="50"
                src="/res/apps/DreiNimmt/3.2.png"
              />
            </BuyItem>
          </div>
          <div className="relative">
            <img alt="" width="100%" src="/res/apps/DreiNimmt/7.png" />
            <BuyItem preis="0.99" onSuccess={() => buyReloader(2, 0.99)}>
              <img
                alt=""
                className="hoverScale"
                style={styles.buyButtonList}
                height="50"
                src="/res/apps/DreiNimmt/8.png"
              />
            </BuyItem>
          </div>
          <div className="relative">
            <img alt="" width="100%" src="/res/apps/DreiNimmt/9.png" />
            <BuyItem preis="4.99" onSuccess={() => buyReloader(10, 4.99)}>
              <img
                alt=""
                className="hoverScale"
                style={styles.buyButtonList}
                height="50"
                src="/res/apps/DreiNimmt/10.png"
              />
            </BuyItem>
          </div>
          <div className="relative">
            <img alt="" width="100%" src="/res/apps/DreiNimmt/11.png" />
            <BuyItem preis="19.99" onSuccess={() => buyReloader(50, 19.99)}>
              <img
                alt=""
                className="hoverScale"
                style={styles.buyButtonList}
                height="50"
                src="/res/apps/DreiNimmt/12.png"
              />
            </BuyItem>
          </div>
          <div className="relative">
            <img alt="" width="100%" src="/res/apps/DreiNimmt/13.png" />
            <BuyItem preis="49.99" onSuccess={() => buyReloader(500, 49.99)}>
              <img
                alt=""
                className="hoverScale"
                style={styles.buyButtonList}
                height="50"
                src="/res/apps/DreiNimmt/14.png"
              />
            </BuyItem>
          </div>
        </Space>
      </Modal>

      <div style={styles.backgroundBox}>
        <div style={styles.background}>
          <canvas
            style={styles.game}
            ref={canvasRef}
            width="410"
            height="410"
          ></canvas>
          <div style={styles.points}>
            Punktezahl: <span ref={pointsRef}></span>
          </div>
          <div style={styles.highscore}>
            Rekord: <span>{String(highscore).padStart(5, "0")}</span>
          </div>
        </div>
        <Divider />
        <Space size={100}>
          <Space direction="vertical">
            <img
              alt=""
              className="hoverScale"
              height="60"
              ref={restartButtonRef}
              src="/res/apps/DreiNimmt/UPElement 15288.png"
            />
            <img
              alt=""
              className="hoverScale"
              height="60"
              onClick={handleClose}
              src="/res/apps/DreiNimmt/UPElement 14288.png"
            />
          </Space>
          <div className="relative">
            <img
              alt=""
              className="hoverScale"
              height="120"
              src="/res/apps/DreiNimmt/UPElement 44288.png"
              onClick={() => {
                let nextReloads = 0;
                if (reloads > 0) {
                  gameRef.mix = true;
                  nextReloads = reloads - 1;
                }
                saveReloads(nextReloads);
              }}
            />
            <div style={styles.reloads}>
              (Noch <span>{reloads}</span> Mal)
            </div>
            <img
              alt=""
              className="hoverScale"
              onClick={() => setBuyWindowVisible(true)}
              style={styles.buyButton}
              height="40"
              src="/res/apps/DreiNimmt/UPElement 39288.png"
            />
          </div>
        </Space>
        <Divider />
      </div>
    </>
  );
};

const styles = {
  reloads: {
    pointerEvents: "none",
    fontSize: "1.2em",
    fontWeight: "bold",
    position: "absolute",
    top: "30px",
    left: "0px",
    width: "100%",
    textAlign: "center",
    color: "yellow",
  },
  game: {
    position: "absolute",
    left: "22px",
    top: "22px",
  },
  backgroundBox: {
    textAlign: "center",
    background: "linear-gradient(90deg,yellow,red)",
    padding: "5px",
  },
  points: {
    fontSize: "1.2em",
    fontWeight: "bold",
    position: "absolute",
    bottom: "95px",
    left: "0px",
    width: "100%",
    textAlign: "center",
  },
  highscore: {
    fontSize: "1.2em",
    fontWeight: "bold",
    position: "absolute",
    bottom: "20px",
    left: "0px",
    width: "100%",
    textAlign: "center",
  },
  background: {
    background: "url('/res/apps/DreiNimmt/UPElement 149288.png')",
    width: "100%",
    height: "600px",
    textAlign: "center",
    backgroundSize: "100% 100%",
    fontSize: "1.2em",
    position: "relative",
  },
  buyButton: {
    position: "absolute",
    left: "10px",
    bottom: "10px",
  },
  buyButtonList: {
    position: "absolute",
    right: "20px",
    bottom: "15px",
  },
  manual: {
    background: "url('/res/apps/DreiNimmt/UPElement 146288.png')",
    textAlign: "center",
    backgroundSize: "100%",
    fontSize: "1.2em",
  },
};
